<template>
    <div id="error-container" class="text-red-600" v-if="error">
        {{ error }}
    </div>
</template>

<script>
export default {
    props: ['error']
}
</script>