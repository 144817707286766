<template>
  <div class="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors py-8">
    <div class="max-w-6xl mx-auto">
      <!-- Header Section -->
      <header class="mb-12 px-4">
        <h1 class="text-3xl font-semibold text-gray-900 dark:text-white text-center">
          Available Tests
        </h1>
      </header>

      <!-- Search and Create Section -->
      <div class="px-4 mb-8 space-y-4">
        <!-- Search Input -->
        <input type="text" v-model="searchQuery" placeholder="Search tests..."
          class="w-full max-w-md mx-auto block px-4 py-2 text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 dark:focus:border-blue-400 focus:ring-1 focus:ring-blue-500 dark:focus:ring-blue-400 transition-colors placeholder-gray-500 dark:placeholder-gray-400">

        <!-- Create Test Button and Run Selected Tests -->
        <div class="flex flex-row justify-center items-center gap-4">
          <button @click="navigateToCreate"
            class="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
            Create New Test
          </button>

          <!-- Updated Run Selected Tests Button -->
          <button @click="runSelectedWorkflows" :disabled="selectedWorkflows.length < 2"
            class="px-6 py-2 bg-green-600 text-white rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed hover:bg-green-700">
            Run {{ selectedWorkflows.length || 0 }} Selected Tests
          </button>
        </div>
      </div>

      <!-- Workflow Grid -->
      <div class="px-4">
        <div class="grid gap-6 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
          <div v-for="workflow in filteredWorkflows" :key="workflow.workflow_id"
            class="bg-white dark:bg-gray-800 rounded-lg shadow-md border border-gray-200 dark:border-gray-700 overflow-hidden">

            <!-- Card Header -->
            <div class="p-4">
              <div class="flex items-start gap-3">
                <input type="checkbox" :value="workflow.workflow_id" v-model="selectedWorkflows"
                  class="mt-1 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500" />
                <!-- Favicon container -->
                <div class="flex-shrink-0 w-6 h-6">
                  <template v-if="getWebsiteUrl(workflow)">
                    <img :src="getFaviconUrl(getWebsiteUrl(workflow))" :alt="getHostname(getWebsiteUrl(workflow))"
                      class="w-full h-full object-contain" @error="(e) => e.target.src = '/favicon-fallback.png'" />
                  </template>
                  <span v-else class="material-icons text-gray-400 dark:text-gray-500">
                    language
                  </span>
                </div>
                <div class="flex-1">
                  <h3 class="text-lg font-medium text-gray-900 dark:text-white">
                    {{ workflow.title }}
                  </h3>
                  <p class="mt-1 text-sm text-gray-500 dark:text-gray-400">
                    {{ workflow.description || 'No description available' }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Card Content - Remove duplicate description -->
            <div class="p-4 space-y-4">
              <!-- Metadata Grid can go here if needed -->
            </div>

            <!-- Card Actions -->
            <div class="p-4 bg-gray-50 dark:bg-gray-800/50 border-t border-gray-200 dark:border-gray-700">
              <div class="flex items-center justify-between gap-2">
                <div class="flex gap-2 items-center">
                  <button @click="runWorkflow(workflow)"
                    class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors flex items-center gap-2"
                    :disabled="isLoading && runningWorkflow === workflow.workflow_id">
                    <span v-if="!isLoading || runningWorkflow !== workflow.workflow_id">Run</span>
                    <span v-else>Running...</span>
                  </button>
                  <router-link :to="`/create/${workflow.workflow_permanent_id}`"
                    class="px-4 py-2 bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-200 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors">
                    Edit
                  </router-link>

                </div>
                <button @click="deleteWorkflow(workflow.workflow_permanent_id)"
                  class="px-4 py-2 text-red-600 hover:bg-red-50 dark:text-red-400 dark:hover:bg-red-900/20 rounded-md transition-colors">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Test List with Checkboxes -->
      <div class="space-y-4 px-4">
        <div v-for="test in tests" :key="test.id"
          class="flex items-center space-x-4 p-4 rounded-lg border border-gray-200 dark:border-gray-700">
          <input type="checkbox" v-model="selectedTests" :value="test"
            class="w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500 appearance-auto">
          <div class="flex-grow">
            <h3 class="text-lg font-medium text-gray-900 dark:text-white">{{ test.name }}</h3>
            <p class="text-sm text-gray-600 dark:text-gray-400">{{ test.description }}</p>
          </div>
        </div>
      </div>

      <!-- Master Run Button -->
      <div v-if="selectedTests.length > 0" class="fixed bottom-8 right-8">
        <button @click="runSelectedTests"
          class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg shadow-lg flex items-center space-x-2">
          <span v-if="!isLoading">Run {{ selectedTests.length }} Selected Tests</span>
          <span v-else>Loading...</span>
          <svg v-if="!isLoading" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <svg v-else class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { BASE_URL } from '../config'
import { swal } from '../utils/swal'

const router = useRouter()
const workflows = ref([])
const error = ref(null)
const searchQuery = ref('')
const isLoading = ref(false)
const runningWorkflow = ref(null)
const selectedTests = ref([])
const selectedWorkflows = ref([])

const filteredWorkflows = computed(() => {
  if (!searchQuery.value) return workflows.value

  const query = searchQuery.value.toLowerCase()
  return workflows.value.filter(workflow =>
    workflow.title?.toLowerCase().includes(query) ||
    workflow.description?.toLowerCase().includes(query)
  )
})

const fetchWorkflows = async () => {
  try {
    const response = await fetch(`${BASE_URL}/workflows?only_saved_tasks=true`, {
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })

    if (!response.ok) throw new Error('Failed to fetch workflows')

    workflows.value = await response.json()
  } catch (err) {
    error.value = err.message
  }
}

const navigateToCreate = () => router.push('/tests/create')

const runWorkflow = async (workflow, isMultiple) => {
  try {
    isLoading.value = true
    runningWorkflow.value = workflow.workflow_id

    const title = workflow.title

    const response = await fetch(`${BASE_URL}/workflows/${workflow.workflow_permanent_id}/run`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: workflow.title,
        proxy_location: workflow.proxy_location || null,
        webhook_callback_url: workflow.webhook_callback_url || null,
        navigation_payload: {}
      })
    })

    if (!response.ok) throw new Error(`Failed to run workflow: ${response.statusText}`)

    const data = await response.json()

    await new Promise(resolve => setTimeout(resolve, 2000))

    let actualTaskId = null

    const startTime = Date.now()

    // while (!actualTaskId && (Date.now() - startTime) < 10000) {
    //   const taskResponse = await fetch(`${BASE_URL}/tasks?task_status=running`, {
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //   })

    //   const taskData = await taskResponse.json()
    //   const runningTask = taskData.filter(tsk => tsk.status === 'running').find(tsk => tsk.request.title === title)

    //   if (runningTask) actualTaskId = runningTask.task_id
    //   else await new Promise(resolve => setTimeout(resolve, 1000))
    // }

    // if (!actualTaskId) throw new Error('Task did not start within 10 seconds')
    // if (!data.task?.task_id) throw new Error('Invalid response from server')
    if (!isMultiple) router.push(`/tasks/${data.task.task_id}`)
    else router.push('/live-tasks')

  } catch (error) {
    console.error('Error running workflow:', error)
    error.value = error.message
  } finally {
    isLoading.value = false
    runningWorkflow.value = null
  }
}

const deleteWorkflow = async (workflowId) => {
  try {
    const result = await swal.confirm(
      'Delete Workflow?',
      'Are you sure you want to delete this workflow? This action cannot be undone.'
    )

    if (result.isConfirmed) {
      const response = await fetch(`${BASE_URL}/workflows/${workflowId}`, {
        method: 'DELETE'
      })

      if (!response.ok) throw new Error('Failed to delete workflow')

      await swal.success('Deleted!', 'The workflow has been deleted successfully.')

      workflows.value = workflows.value.filter(w => w.workflow_permanent_id !== workflowId)
    }
  } catch (error) {
    console.error('Error deleting workflow:', error)
    await swal.error('Error!', 'Failed to delete the workflow. Please try again.')
  }
}

const getWebsiteUrl = (workflow) => {
  const firstBlock = workflow.workflow_definition?.blocks?.[0]
  return firstBlock?.url || ''
}

const getFaviconUrl = (url) => {
  try {
    const domain = new URL(url).hostname
    return `https://www.google.com/s2/favicons?domain=${domain}&sz=32`
  } catch {
    return ''
  }
}

const getHostname = (url) => {
  try {
    return new URL(url).hostname
  } catch {
    return ''
  }
}

const runSelectedTests = async () => {
  isLoading.value = true
  try {
    selectedTests.value.forEach(test => {
      runTest(test)
    })
  } catch (error) {
    // Handle error
  } finally {
    isLoading.value = false
  }
}

const runSelectedWorkflows = async () => {
  for (const workflowId of selectedWorkflows.value) {
    const workflow = workflows.value.find(w => w.workflow_id === workflowId)

    if (workflow) await runWorkflow(workflow, true)
  }
}

onMounted(fetchWorkflows)
</script>

<style scoped>
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Add smooth transitions for dark mode */
.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* Smooth transitions */
.fixed {
  transition: all 0.2s ease-in-out;
}

.tests-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.button-group {
  display: flex;
  gap: 0.5rem;
}

.create-button,
.run-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.create-button:hover,
.run-button:hover {
  background-color: #f5f5f5;
}

.create-button:disabled,
.run-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>