<template>
  <div class="min-h-screen py-8 bg-gray-50 dark:bg-gray-900 transition-colors">
    <!-- Auth Modal -->
    <AuthModal :show="showAuthModal" @close="showAuthModal = false" @auth-success="handleAuthSuccess" />

    <!-- Test Creation Modal -->
    <CreateTestModal :show="showCreateTestModal" :workflows="workflows" @close="showCreateTestModal = false"
      @test-created="handleTestCreated" />

    <div class="max-w-6xl mx-auto">
      <!-- Header Section -->
      <header class="text-center mb-12 relative">
        <h1 class="text-3xl font-semibold text-gray-900 dark:text-white">Render Better QA Suite</h1>
        <p class="mt-2 text-lg text-gray-600 dark:text-gray-400">Automated Testing & Quality Assurance Platform</p>
      </header>

      <!-- Main Actions Grid -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 px-4 mb-12">
        <!-- Test Results Card -->
        <router-link to="/testResults"
          class="group bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md transition-all p-6 border border-gray-200 dark:border-gray-700 cursor-pointer">
          <div class="flex items-start space-x-4">
            <div class="p-3 bg-blue-50 dark:bg-blue-900/50 rounded-lg group-hover:bg-blue-100 dark:group-hover:bg-blue-900/70 transition-colors">
              <svg class="w-8 h-8 text-blue-600 dark:text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 0 002-2M9 5a2 2 0 012-2h2a2 0 012 2m-6 9l2 2 4-4" />
              </svg>
            </div>
            <div class="flex-1">
              <h2 class="text-xl font-semibold text-gray-900 dark:text-white group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors">
                Test Results
              </h2>
              <p class="mt-2 text-gray-600 dark:text-gray-400">
                {{ recentTests }} tests completed in the last 24 hours
              </p>
              <div class="mt-4 flex items-center text-sm text-blue-600 dark:text-blue-400">
                View Reports
                <svg class="w-4 h-4 ml-1 group-hover:translate-x-1 transition-transform" fill="none"
                  stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
              </div>
            </div>
          </div>
        </router-link>

        <!-- Run Tests Card -->
        <router-link to="/availableTests"
          class="group bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md transition-all p-6 border border-gray-200 dark:border-gray-700 cursor-pointer">
          <div class="flex items-start space-x-4">
            <div class="p-3 bg-green-50 dark:bg-green-900/50 rounded-lg group-hover:bg-green-100 dark:group-hover:bg-green-900/70 transition-colors">
              <svg class="w-8 h-8 text-green-600 dark:text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="flex-1">
              <h2 class="text-xl font-semibold text-gray-900 dark:text-white group-hover:text-green-600 dark:group-hover:text-green-400 transition-colors">
                Tests
              </h2>
              <p class="mt-2 text-gray-600 dark:text-gray-400">
                {{ totalWorkflows }} test workflows available to run
              </p>
              <div class="mt-4 flex items-center text-sm text-green-600 dark:text-green-400">
                Start Testing
                <svg class="w-4 h-4 ml-1 group-hover:translate-x-1 transition-transform" fill="none"
                  stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
              </div>
            </div>
          </div>
        </router-link>

        <!-- Live Tasks Card -->
        <router-link to="/live-tasks"
          class="group bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md transition-all p-6 border border-gray-200 dark:border-gray-700 cursor-pointer">
          <div class="flex items-start space-x-4">
            <div class="p-3 bg-purple-50 dark:bg-purple-900/50 rounded-lg group-hover:bg-purple-100 dark:group-hover:bg-purple-900/70 transition-colors">
              <svg class="w-8 h-8 text-purple-600 dark:text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
              </svg>
            </div>
            <div class="flex-1">
              <h2 class="text-xl font-semibold text-gray-900 dark:text-white group-hover:text-purple-600 dark:group-hover:text-purple-400 transition-colors">Live Tasks</h2>
              <p class="mt-2 text-gray-600 dark:text-gray-400">Monitor running test executions</p>
              <div class="mt-4 flex items-center text-sm text-purple-600 dark:text-purple-400">
                View Live Tasks
                <svg class="w-4 h-4 ml-1 group-hover:translate-x-1 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
              </div>
            </div>
          </div>
        </router-link>

        <!-- Workflow Automation Card -->
        <router-link to="/workflow-automation"
          class="group bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md transition-all p-6 border border-gray-200 dark:border-gray-700 cursor-pointer">
          <div class="flex items-start space-x-4">
            <div class="p-3 bg-orange-50 dark:bg-orange-900/50 rounded-lg group-hover:bg-orange-100 dark:group-hover:bg-orange-900/70 transition-colors">
              <svg class="w-8 h-8 text-orange-600 dark:text-orange-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="flex-1">
              <h2 class="text-xl font-semibold text-gray-900 dark:text-white group-hover:text-orange-600 dark:group-hover:text-orange-400 transition-colors">
                Workflow Automation
              </h2>
              <p class="mt-2 text-gray-600 dark:text-gray-400">Schedule and manage automated test runs</p>
              <div class="mt-4 flex items-center text-sm text-orange-600 dark:text-orange-400">
                Configure Automation
                <svg class="w-4 h-4 ml-1 group-hover:translate-x-1 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <!-- Quick Stats Section -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6 px-4">
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6 border border-gray-200 dark:border-gray-700">
          <h3 class="text-lg font-medium text-gray-900 dark:text-white">Recent Tests</h3>
          <p class="mt-2 text-3xl font-semibold text-blue-600 dark:text-blue-400">{{ recentTests }}</p>
          <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">In the last 24 hours</p>
        </div>
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6 border border-gray-200 dark:border-gray-700">
          <h3 class="text-lg font-medium text-gray-900 dark:text-white">Success Rate</h3>
          <p class="mt-2 text-3xl font-semibold text-green-600 dark:text-green-400">{{ successRate }}%</p>
          <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">Average this week</p>
        </div>
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6 border border-gray-200 dark:border-gray-700">
          <h3 class="text-lg font-medium text-gray-900 dark:text-white">Active Workflows</h3>
          <p class="mt-2 text-3xl font-semibold text-purple-600 dark:text-purple-400">{{ totalWorkflows }}</p>
          <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">Currently configured</p>
        </div>
      </div>
      
      <!-- Add after the existing grid sections -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6 px-4">
        <!-- ... existing stats cards ... -->

        <!-- Add new Live Tasks link -->
        <!-- <router-link to="/testResults" class="...">
          Test Results
        </router-link> -->

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { BASE_URL } from '../config'
import AuthModal from '../components/auth/AuthModal.vue'
import CreateTestModal from '../components/CreateTestModal.vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const showAuthModal = ref(false)
const isAuthenticated = ref(false)

// State
const tasks = ref([])
const workflows = ref([])
const error = ref(null)

// Computed values
const totalTasks = ref(0)
const totalWorkflows = ref(0)
const recentTests = ref(0)
const successRate = ref(0)

const showCreateTestModal = ref(false)

const checkAuth = async () => {
  try {
    const response = await fetch(`${BASE_URL}/auth/check`, {
      credentials: 'include'
    })
    const data = await response.json()
    isAuthenticated.value = data.isAuthenticated

    if (!data.isAuthenticated) {
      showAuthModal.value = true
    } else {
      // Fetch data only if authenticated
      await fetchData()
    }
  } catch (err) {
    error.value = err.message
  }
}

const handleAuthSuccess = (user) => {
  isAuthenticated.value = true
  showAuthModal.value = false
  fetchData() // Fetch data after successful authentication
}

const handleSignOut = async () => {
  try {
    const response = await fetch(`${BASE_URL}/auth/logout`, {
      method: 'POST',
      credentials: 'include'
    })

    if (!response.ok) {
      throw new Error('Failed to sign out')
    }

    isAuthenticated.value = false

    // Reset all data
    tasks.value = []
    workflows.value = []
    totalTasks.value = 0
    totalWorkflows.value = 0
    recentTests.value = 0
    successRate.value = 0

    // Redirect to login page instead of showing modal
    router.push('/login')
  } catch (err) {
    error.value = err.message
  }
}

const fetchData = async () => {
  try {
    const [tasksResponse, workflowResponse] = await Promise.all([
      fetch(`${BASE_URL}/tasks`, {
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'  // Add this
      }),
      fetch(`${BASE_URL}/workflows`, {
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'  // Add this
      })
    ])

    if (!tasksResponse.ok || !workflowResponse.ok) {
      throw new Error('Failed to fetch data')
    }

    const tasksData = await tasksResponse.json()
    const workflowsData = await workflowResponse.json()

    // Update state
    tasks.value = tasksData
    workflows.value = workflowsData

    // Calculate stats
    totalTasks.value = tasksData.length
    totalWorkflows.value = workflowsData.length

    // Calculate recent tests (last 24 hours)
    const last24Hours = new Date(Date.now() - 24 * 60 * 60 * 1000)
    recentTests.value = tasksData.filter(task =>
      new Date(task.created_at) > last24Hours
    ).length

    // Calculate success rate
    const completedTasks = tasksData.filter(task => task.status === 'completed')
    successRate.value = Math.round((completedTasks.length / tasksData.length) * 100) || 0
  } catch (err) {
    console.error('Error fetching data:', err)
    error.value = err.message
  }
}

const handleTestCreated = async (testData) => {
  try {
    const response = await fetch(`${BASE_URL}/tasks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'include'
      },
      body: JSON.stringify(testData)
    })

    if (!response.ok) {
      throw new Error('Failed to create test')
    }

    showCreateTestModal.value = false
    await fetchData() // Refresh data
  } catch (err) {
    error.value = err.message
  }
}

const navigateToTask = (taskId) => {
  router.push(`/results?task=${taskId}`)
}

const formatTimeAgo = (dateString) => {
  const date = new Date(dateString)
  const now = new Date()
  const seconds = Math.floor((now - date) / 1000)

  if (seconds < 60) return 'just now'
  if (seconds < 3600) return `${Math.floor(seconds / 60)}m ago`
  if (seconds < 86400) return `${Math.floor(seconds / 3600)}h ago`
  return `${Math.floor(seconds / 86400)}d ago`
}

onMounted(() => {
  checkAuth()
})
</script>