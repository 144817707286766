import Swal from 'sweetalert2'

export const swal = {
  confirm: (title = 'Are you sure?', text = 'This action cannot be undone.') => {
    return Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc2626', // red-600
      cancelButtonColor: '#6b7280', // gray-500
      confirmButtonText: 'Yes!',
      cancelButtonText: 'Cancel'
    })
  },

  success: (title, text) => {
    return Swal.fire({
      title,
      text,
      icon: 'success',
      confirmButtonColor: '#3b82f6' // blue-500
    })
  },

  error: (title, text) => {
    return Swal.fire({
      title,
      text, 
      icon: 'error',
      confirmButtonColor: '#3b82f6' // blue-500
    })
  }
}