<template>
    <nav
        class="bg-white dark:bg-gray-800 shadow-sm border-b border-gray-200 dark:border-gray-700 fixed w-full top-0 z-50 transition-colors">
        <div class="mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex h-16">
                <!-- Left - Logo -->
                <div class="flex-shrink-0 flex items-center">
                    <router-link to="/" class="flex items-center">
                        <img src="/rb.svg" alt="Render Better Logo" class="h-8 w-auto" />
                    </router-link>
                </div>

                <!-- Right - Theme Toggle & User Menu -->
                <div class="flex-1 flex justify-end items-center">
                    <!-- Navigation Dropdown -->
                    <div class="relative inline-block text-left mr-3 nav-dropdown">
                        <button @click="toggleNavDropdown"
                            class="flex items-center p-2 rounded-full transition-colors hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500">
                            <div
                                class="w-8 h-8 rounded-full bg-purple-100 dark:bg-purple-700 flex items-center justify-center text-purple-600 dark:text-purple-200">
                                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            </div>
                        </button>

                        <div v-if="isNavDropdownOpen"
                            class="fixed mt-2 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                            :style="{
                                top: '4rem',
                                right: 'auto',
                                minWidth: '200px',
                                transform: 'translateX(-45%)'
                            }">
                            <router-link v-for="item in navigationItems" :key="item.path" :to="item.path"
                                class="flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                                @click="closeNavDropdown">
                                {{ item.name }}
                            </router-link>
                        </div>
                    </div>

                    <!-- Theme Toggle -->
                    <button @click="toggleTheme"
                        class="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500">
                        <svg v-if="isDarkMode" class="h-5 w-5 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                        </svg>
                        <svg v-else class="h-5 w-5 text-gray-600" fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd"
                                d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>

                    <!-- User Dropdown Container -->
                    <div class="relative inline-block text-left ml-3 user-dropdown">
                        <button @click="isDropdownOpen = !isDropdownOpen"
                            class="flex items-center p-2 rounded-full transition-colors hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500">
                            <div
                                class="w-8 h-8 rounded-full bg-purple-100 dark:bg-purple-700 flex items-center justify-center text-purple-600 dark:text-purple-200">
                                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                            </div>
                        </button>

                        <transition enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95">
                            <div v-if="isDropdownOpen"
                                class="fixed mt-2 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                                :style="{
                                    top: '4rem',
                                    right: '1rem',
                                    minWidth: '200px'
                                }">
                                <router-link to="/settings"
                                    class="flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700">
                                    <svg class="w-4 h-4 mr-3 text-gray-500 dark:text-gray-400" fill="none"
                                        stroke="currentColor" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37.996.608 2.296.07 2.572-1.065z">
                                        </path>
                                    </svg>
                                    Settings
                                </router-link>
                                <button @click="signOut"
                                    class="flex w-full items-center px-4 py-2 text-sm text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-600">
                                    <svg class="w-4 h-4 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1">
                                        </path>
                                    </svg>
                                    Sign Out
                                </button>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { BASE_URL } from '../config'
import { useTheme } from '../composables/useTheme'

const router = useRouter()
const isDropdownOpen = ref(false)
const isNavDropdownOpen = ref(false)
const { isDarkMode, toggleTheme, initTheme } = useTheme()

const closeDropdown = () => isDropdownOpen.value = false

const handleClickOutside = (event) => {
    if (!event.target.closest('.user-dropdown')) isDropdownOpen.value = false
    if (!event.target.closest('.nav-dropdown')) isNavDropdownOpen.value = false
}

const signOut = async () => {
    try {
        const response = await fetch(`${BASE_URL}/auth/logout`, {
            method: 'POST',
            credentials: 'include'
        })

        if (!response.ok) throw new Error('Failed to sign out')

        closeDropdown()

        router.push('/login')
    } catch (error) {
        console.error('Sign out failed:', error)
    }
}

const navigationItems = [
    { name: 'Home', path: '/' },
    { name: 'Test Suite', path: '/testResults' },
    { name: 'Available Tests', path: '/availableTests' },
    { name: 'Live Tasks', path: '/live-tasks' },
    { name: 'Settings', path: '/settings' }
]

const toggleNavDropdown = () => isNavDropdownOpen.value = !isNavDropdownOpen.value
const closeNavDropdown = () => isNavDropdownOpen.value = false

onMounted(() => {
    initTheme()
    document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside)
})
</script>