<template>
    <div v-if="show" class="fixed inset-0 z-50 overflow-y-auto">
      <!-- Backdrop -->
      <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>
      
      <!-- Modal -->
      <div class="flex min-h-full items-center justify-center p-4">
        <div class="relative transform overflow-hidden rounded-xl bg-white shadow-xl transition-all w-full max-w-md">
          <!-- Tabs -->
          <div class="flex border-b border-gray-200">
            <button 
              @click="activeTab = 'login'"
              :class="[
                'flex-1 py-4 px-4 text-sm font-medium text-center focus:outline-none',
                activeTab === 'login' 
                  ? 'text-blue-600 border-b-2 border-blue-600' 
                  : 'text-gray-500 hover:text-gray-700'
              ]"
            >
              Sign In
            </button>
            <button 
              @click="activeTab = 'register'"
              :class="[
                'flex-1 py-4 px-4 text-sm font-medium text-center focus:outline-none',
                activeTab === 'register' 
                  ? 'text-blue-600 border-b-2 border-blue-600' 
                  : 'text-gray-500 hover:text-gray-700'
              ]"
            >
              Sign Up
            </button>
          </div>

          <!-- Login Form -->
          <div v-if="activeTab === 'login'" class="p-6">
            <form @submit.prevent="handleLogin">
              <div class="space-y-4">
                <div>
                  <label for="login-email" class="block text-sm font-medium text-gray-700">Email</label>
                  <input 
                    id="login-email"
                    v-model="loginForm.email"
                    type="email"
                    required
                    class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  >
                </div>
                <div>
                  <label for="login-password" class="block text-sm font-medium text-gray-700">Password</label>
                  <input 
                    id="login-password"
                    v-model="loginForm.password"
                    type="password"
                    required
                    class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  >
                </div>
                <div v-if="error" class="text-red-600 text-sm">
                  {{ error }}
                </div>
                <button 
                  type="submit"
                  class="w-full rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>

          <!-- Register Form -->
          <div v-if="activeTab === 'register'" class="p-6">
            <form @submit.prevent="handleRegister">
              <div class="space-y-4">
                <div>
                  <label for="register-email" class="block text-sm font-medium text-gray-700">Email</label>
                  <input 
                    id="register-email"
                    v-model="registerForm.email"
                    type="email"
                    required
                    class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  >
                </div>
                <div>
                  <label for="register-password" class="block text-sm font-medium text-gray-700">Password</label>
                  <input 
                    id="register-password"
                    v-model="registerForm.password"
                    type="password"
                    required
                    class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  >
                </div>
                <div v-if="error" class="text-red-600 text-sm">
                  {{ error }}
                </div>
                <button 
                  type="submit"
                  class="w-full rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { BASE_URL } from '../../config'

const props = defineProps({
    show: {
        type: Boolean,
        required: true
    }
})

const emit = defineEmits(['close', 'auth-success'])

const activeTab = ref('login')
const error = ref(null)

const loginForm = ref({
    email: '',
    password: ''
})

const registerForm = ref({
    email: '',
    password: ''
})

const handleLogin = async () => {
    try {
        const response = await fetch(`${BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginForm.value),
            credentials: 'include'
        })

        const data = await response.json()
        
        if (!response.ok) {
            throw new Error(data.error || 'Login failed')
        }

        emit('auth-success', data.user)
    } catch (err) {
        error.value = err.message
    }
}

const handleRegister = async () => {
    try {
        const response = await fetch(`${BASE_URL}/auth/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(registerForm.value)
        })

        const data = await response.json()
        
        if (!response.ok) {
            throw new Error(data.error || 'Registration failed')
        }

        // Automatically log in after successful registration
        loginForm.value = {
            email: registerForm.value.email,
            password: registerForm.value.password
        }
        await handleLogin()
    } catch (err) {
        error.value = err.message
    }
}

const handleAuthSuccess = async (userData) => {
    try {
        emit('auth-success', userData)
    } catch (err) {
        error.value = err.message
    }
}
</script>