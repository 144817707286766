<template>
  <div class="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors">
    <!-- Material Back Button -->
    <div class="max-w-6xl mx-auto pt-6 px-4">
      <router-link 
        to="/testResults"
        class="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors group"
      >
        <svg 
          class="w-5 h-5 mr-2 transform group-hover:-translate-x-1 transition-transform" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="2" 
            d="M10 19l-7-7m0 0l7-7m-7 7h18"
          />
        </svg>
        Back to Tests
      </router-link>
    </div>

    <!-- Main Content Container -->
    <div class="max-w-6xl mx-auto py-8">

      <!-- Main Content -->
      <div class="space-y-6">
        <!-- Task Details Card -->
        <div
          class="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden transition-colors">
          <TaskDetails :task="task" />
        </div>

        <!-- Steps Card -->
        <div
          class="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden transition-colors">
          <TaskSteps 
            :steps="steps" 
            :artifacts="artifacts" 
            :test-status="task.status"
            :failure-reason="task.failure_reason"
            ref="taskSteps" 
          />
        </div>

        <!-- Recording Section Card -->
        <div v-if="task.recording_url"
          class="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden transition-colors">
          <div class="px-6 py-4 border-b border-gray-200 dark:border-gray-700">
            <h2 class="text-lg font-medium text-gray-900 dark:text-white">Test Recording</h2>
          </div>
          <RecordingSection :recordingUrl="task.recording_url" />
        </div>
      </div>

      <!-- Error Display -->
      <ErrorContainer v-if="error" :error="error"
        class="mt-6 bg-red-50 dark:bg-red-900/50 text-red-700 dark:text-red-200 transition-colors" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { BASE_URL } from '../config'
import TaskDetails from '../components/TaskDetails.vue'
import TaskSteps from '../components/TaskSteps.vue'
import RecordingSection from '../components/RecordingSection.vue'
import ErrorContainer from '../components/ErrorContainer.vue'

const reportDate = ref(new Date().toLocaleDateString())
const task = ref({})
const steps = ref([])
const error = ref(null)
const tasks = ref([])
const workflows = ref([])
const artifacts = ref({})
const route = useRoute()
const taskId = ref(route.params.taskId)
const taskTitle = ref(route.query.taskTitle)
const taskSteps = ref(null)

// Fetch task data and steps
const fetchTaskData = async (taskId) => {
  try {
    const [taskResponse, stepsResponse] = await Promise.all([
      fetch(`${BASE_URL}/tasks/${taskId}`, {
        headers: { "Content-Type": "application/json" },
      }),
      fetch(`${BASE_URL}/tasks/${taskId}/steps`, {
        headers: { "Content-Type": "application/json" },
      })
    ])

    if (!taskResponse.ok || !stepsResponse.ok) {
      throw new Error('Failed to fetch data')
    }

    const taskData = await taskResponse.json()
    steps.value = await stepsResponse.json()

    // Just merge the title from route query into the task data
    task.value = {
      ...taskData,
      request: {
        ...taskData.request,
        title: taskTitle.value || taskData.request?.title
      }
    }

    console.log(route.state)
    // console.log(task)
  } catch (err) {
    error.value = err.message
  }
}

// Fetch artifacts for a step
const fetchStepArtifacts = async (taskId, stepId) => {
  try {
    const response = await fetch(
      `${BASE_URL}/tasks/${taskId}/steps/${stepId}/artifacts`,
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    const data = await response.json()
    artifacts.value[stepId] = data
  } catch (err) {
    console.error("Error loading artifacts:", err)
  }
}

// Fetch data
const fetchData = async () => {
  try {
    const taskId = route.params.taskId
    await fetchTaskData(taskId)
  } catch (err) {
    error.value = err.message
  }
}

// Initialize data on mount
onMounted(() => {
  console.log('were mounted baby')
  fetchTaskData(taskId.value)
})

const expandAllSteps = () => {
  taskSteps.value?.expandAll()
}

const collapseAllSteps = () => {
  taskSteps.value?.collapseAll()
}
</script>

<style scoped>
.task-status {
  @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium;
}

.task-status.completed {
  @apply bg-green-100 text-green-800;
}

.task-status.failed {
  @apply bg-red-100 text-red-800;
}

.task-status.running {
  @apply bg-blue-100 text-blue-800;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
</style>