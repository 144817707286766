export const BASE_URL = "/api/v1"
export const ARTIFACT_URL = "/artifact"

export const sanitizeFilePath = (path) => {
    if (!path) return ""
    // Handle both file:/// and direct paths
    if (path.startsWith("file://")) {
        path = path.slice(7)
    }
    // Ensure path starts with /data if it contains /data
    if (path.includes("/data") && !path.startsWith("/data")) {
        path = "/data" + path.substring(path.indexOf("/data") + 5)
    }
    return path
}