import { ref } from 'vue'

// Create a single shared isDarkMode ref
const isDarkMode = ref(localStorage.getItem('theme') === 'dark' || 
    (!localStorage.getItem('theme') && window.matchMedia('(prefers-color-scheme: dark)').matches))

export function useTheme() {
    const toggleTheme = () => {
        isDarkMode.value = !isDarkMode.value
        if (isDarkMode.value) {
            document.documentElement.classList.add('dark')
            localStorage.setItem('theme', 'dark')
        } else {
            document.documentElement.classList.remove('dark')
            localStorage.setItem('theme', 'light')
        }
    }

    const initTheme = () => {
        // Initialize based on the shared state
        if (isDarkMode.value) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }

    return {
        isDarkMode,
        toggleTheme,
        initTheme
    }
}