<template>
    <div v-if="show" class="fixed inset-0 z-50 overflow-y-auto">
        <!-- Backdrop -->
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>

        <!-- Modal -->
        <div class="flex min-h-full items-center justify-center p-4">
            <div
                class="relative transform overflow-hidden rounded-xl bg-white shadow-xl transition-all w-full max-w-lg">
                <div class="px-6 py-4 border-b border-gray-200">
                    <h3 class="text-lg font-medium text-gray-900">Create New Test</h3>
                </div>

                <form @submit.prevent="handleSubmit" class="p-6">
                    <div class="space-y-4">
                        <!-- Workflow Selection -->
                        <div>
                            <label for="workflow" class="block text-sm font-medium text-gray-700">Select
                                Workflow</label>
                            <select id="workflow" v-model="selectedWorkflow" required
                                class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500">
                                <option value="">Select a workflow</option>
                                <option v-for="workflow in workflows" :key="workflow.id" :value="workflow">
                                    {{ workflow.title }}
                                </option>
                            </select>
                        </div>

                        <!-- URL Input -->
                        <div>
                            <label for="url" class="block text-sm font-medium text-gray-700">Target URL</label>
                            <input id="url" v-model="url" type="url" required placeholder="https://example.com"
                                class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500">
                        </div>

                        <!-- Error Display -->
                        <div v-if="error" class="text-red-600 text-sm">
                            {{ error }}
                        </div>

                        <!-- Buttons -->
                        <div class="mt-6 flex justify-end space-x-3">
                            <button type="button" @click="$emit('close')"
                                class="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900">
                                Cancel
                            </button>
                            <button type="submit"
                                class="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                :disabled="isSubmitting">
                                {{ isSubmitting ? 'Creating...' : 'Create Test' }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
    show: Boolean,
    workflows: Array
})

const emit = defineEmits(['close', 'test-created'])

const selectedWorkflow = ref('')
const url = ref('')
const error = ref(null)
const isSubmitting = ref(false)

const handleSubmit = async () => {
    try {
        isSubmitting.value = true
        error.value = null

        const testData = {
            workflowId: selectedWorkflow.value.id,
            url: url.value
        }

        emit('test-created', testData)

        // Reset form
        selectedWorkflow.value = ''
        url.value = ''

    } catch (err) {
        error.value = err.message
    } finally {
        isSubmitting.value = false
    }
}
</script>