import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import TestResults from '../views/TestResults.vue'
import AvailableTests from '../views/AvailableTests.vue'
import LoginView from '../views/LoginView.vue'
import LiveTasksView from '../views/LiveTasksView.vue'
import SettingsView from '../views/SettingsView.vue'
import CompletedAndRunningTasksView from '../views/CompletedAndRunningTasksView.vue'
import CreateNewTest from '../views/CreateNewTest.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/results',
    name: 'TestResults',
    component: TestResults,
    meta: { requiresAuth: true }
  },
  {
    path: '/availableTests',
    name: 'AvailableTests',
    component: () => import('../views/AvailableTests.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/live-tasks',
    name: 'LiveTasks',
    component: LiveTasksView,
    meta: { requiresAuth: true }
  },
  {
    path: '/tasks/:taskId/actions',
    name: 'TaskDetails',
    component: () => import('../views/TaskDetailsView.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/create/:id',
    name: 'TaskTemplate',
    component: () => import('../views/TaskTemplate.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/testResults',
    name: 'Tests',
    component: () => import('../views/Tests.vue')
  },
  {
    path: '/workflow-automation',
    name: 'WorkflowAutomation',
    component: () => import('../views/WorkflowAutomation.vue')
  },
  {
    path: '/results/:taskId',
    name: 'TestResults',
    component: () => import('../views/TestResults.vue'),
    props: route => ({
      taskId: route.params.taskId,
      taskData: route.query.taskData ? JSON.parse(route.query.taskData) : null,
      stepsData: route.query.stepsData ? JSON.parse(route.query.stepsData) : null
    })
  },
  {
    path: '/tasks/:taskId',
    name: 'CompletedAndRunningTasksView',
    component: CompletedAndRunningTasksView,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/tests/create',
    name: 'CreateNewTest',
    component: CreateNewTest
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Navigation guard
router.beforeEach(async (to, from, next) => {
  // Skip auth check for login page
  if (to.path === '/login') {
    return next()
  }

  // Check auth for all other routes
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const response = await fetch('/api/v1/auth/check', {
      credentials: 'include'
    })
    const data = await response.json()
    
    if (!data.isAuthenticated) {
      next('/login')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router