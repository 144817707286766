<template>
    <div class="min-h-screen flex">
        <!-- Left Panel - Login Form -->
        <div class="w-1/2 flex flex-col justify-center items-center p-16 bg-white">
            <div class="w-full max-w-md">
                <img src="/rb.svg" alt="Render Better Logo" class="h-12 w-auto mb-8" />
                <h1 class="text-3xl font-semibold text-gray-900 mb-2">Welcome Back</h1>
                <p class="text-lg text-gray-600 mb-8">Sign in to access your QA dashboard</p>

                <form @submit.prevent="handleLogin" class="space-y-6">
                    <div>
                        <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                        <input id="email" v-model="loginForm.email" type="email" required
                            class="mt-1 block w-full rounded-md border border-gray-300 px-4 py-3 text-gray-900 placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                            placeholder="Enter your email">
                    </div>

                    <div>
                        <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
                        <input id="password" v-model="loginForm.password" type="password" required
                            class="mt-1 block w-full rounded-md border border-gray-300 px-4 py-3 text-gray-900 placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                            placeholder="Enter your password">
                    </div>

                    <div v-if="error" class="rounded-md bg-red-50 p-4 text-sm text-red-700">
                        {{ error }}
                    </div>

                    <button type="submit"
                        class="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        Sign In
                    </button>
                </form>
            </div>
        </div>

        <!-- Right Panel - Decorative Content -->
        <div class="w-1/2 bg-gray-50 flex flex-col justify-center items-center p-16">
            <div class="max-w-lg text-center">
                <div class="mb-8">
                    <!-- Decorative illustration or pattern -->
                    <svg class="w-64 h-64 mx-auto text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v3m0 0v3m0-3h3m-3 0H9" />
                    </svg>
                </div>
                <h2 class="text-2xl font-semibold text-gray-900 mb-4">
                    Automated QA Testing Platform
                </h2>
                <p class="text-lg text-gray-600">
                    Streamline your quality assurance process with automated testing, detailed reports, and
                    comprehensive analytics.
                </p>
            </div>

            <!-- Bottom decorative elements -->
            <div class="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-8">
                <div class="flex space-x-3">
                    <div class="w-3 h-3 rounded-full bg-blue-600"></div>
                    <div class="w-3 h-3 rounded-full bg-blue-400"></div>
                    <div class="w-3 h-3 rounded-full bg-blue-300"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { BASE_URL } from '../config'

const router = useRouter()
const error = ref(null)
const loginForm = ref({
    email: '',
    password: ''
})

const handleLogin = async () => {
    try {
        const response = await fetch(`${BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginForm.value),
            credentials: 'include'
        })

        const data = await response.json()

        if (!response.ok) {
            throw new Error(data.error || 'Login failed')
        }

        // Redirect to home page on successful login
        router.push('/')
    } catch (err) {
        error.value = err.message
    }
}
</script>