<template>
    <div class="min-h-screen py-8 bg-gray-50 dark:bg-gray-900 transition-colors">
        <div class="max-w-3xl mx-auto px-4">
            <header class="mb-8">
                <h1 class="text-3xl font-semibold text-gray-900 dark:text-white">Settings</h1>
                <p class="mt-2 text-gray-600 dark:text-gray-400">Customize your experience</p>
            </header>

            <!-- Settings Sections -->
            <div class="space-y-6">
                <!-- Account Section -->
                <div class="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
                    <div class="p-6">
                        <h2 class="text-lg font-medium text-gray-900 dark:text-white mb-4">Account Details</h2>
                        <div class="space-y-4">
                            <div>
                                <label class="text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
                                <p class="mt-1 text-sm text-gray-900 dark:text-white">{{ userDetails?.email }}</p>
                            </div>
                            <div>
                                <label class="text-sm font-medium text-gray-700 dark:text-gray-300">Role</label>
                                <p class="mt-1 text-sm text-gray-900 dark:text-white capitalize">{{ userDetails?.role }}</p>
                            </div>
                            <div>
                                <label class="text-sm font-medium text-gray-700 dark:text-gray-300">Permissions</label>
                                <div class="mt-1 space-y-1">
                                    <span 
                                        v-for="permission in userDetails?.permissions" 
                                        :key="permission"
                                        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200 mr-2 mb-2"
                                    >
                                        {{ formatPermission(permission) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <label class="text-sm font-medium text-gray-700 dark:text-gray-300">Last Login</label>
                                <p class="mt-1 text-sm text-gray-900 dark:text-white">{{ formatDate(userDetails?.lastLogin) }}</p>
                            </div>
                            <div>
                                <label class="text-sm font-medium text-gray-700 dark:text-gray-300">Member Since</label>
                                <p class="mt-1 text-sm text-gray-900 dark:text-white">{{ formatDate(userDetails?.createdAt) }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Appearance Section -->
                <div class="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
                    <div class="p-6">
                        <h2 class="text-lg font-medium text-gray-900 dark:text-white mb-4">Appearance</h2>
                        <div class="flex items-center justify-between">
                            <div>
                                <label class="text-sm font-medium text-gray-700 dark:text-gray-300">Theme</label>
                                <p class="text-sm text-gray-500 dark:text-gray-400">Choose between light and dark mode</p>
                            </div>
                            <button @click="toggleTheme"
                                class="relative inline-flex h-6 w-11 items-center rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                :class="isDarkMode ? 'bg-blue-600' : 'bg-gray-200'">
                                <span class="sr-only">Toggle theme</span>
                                <span class="inline-block h-4 w-4 transform rounded-full bg-white transition"
                                    :class="isDarkMode ? 'translate-x-6' : 'translate-x-1'"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useTheme } from '../composables/useTheme'
import { BASE_URL } from '../config'

const { isDarkMode, toggleTheme, initTheme } = useTheme()
const userDetails = ref(null)

const formatDate = (dateString) => {
    if (!dateString) return 'N/A'
    return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })
}

const formatPermission = (permission) => {
    return permission.split('_').map(word => 
        word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ')
}

const fetchUserDetails = async () => {
    try {
        const response = await fetch(`${BASE_URL}/auth/user`, {
            credentials: 'include'
        })
        if (!response.ok) throw new Error('Failed to fetch user details')
        const data = await response.json()
        userDetails.value = data.user
    } catch (error) {
        console.error('Error fetching user details:', error)
    }
}

onMounted(() => {
    initTheme()
    fetchUserDetails()
})
</script>