<template>
    <div>
        <div class="px-6 py-4 border-b border-gray-200 dark:border-gray-700">
            <div class="flex justify-between items-center">
                <h2 class="text-lg font-medium text-gray-900 dark:text-white">Test Steps</h2>
                <div class="flex gap-2">
                    <button @click="expandAll" 
                        class="px-3 py-1.5 text-sm font-medium text-blue-600 dark:text-blue-400 hover:bg-blue-50 dark:hover:bg-blue-900/50 rounded-md transition-colors">
                        Expand All
                    </button>
                    <button @click="collapseAll"
                        class="px-3 py-1.5 text-sm font-medium text-blue-600 dark:text-blue-400 hover:bg-blue-50 dark:hover:bg-blue-900/50 rounded-md transition-colors">
                        Collapse All
                    </button>
                </div>
            </div>
        </div>
        <div class="space-y-4 p-6">
            <!-- Regular Steps -->
            <StepItem 
                v-for="(step, index) in steps" 
                :key="step.id" 
                :step="step" 
                :index="index"
                ref="stepRefs" 
            />
            
            <!-- Failure Reason Step (Only shown for failed tests) -->
            <StepItem 
                v-if="testStatus === 'failed'"
                :step="{
                    step_id: 'failure',
                    output: {
                        actions_and_results: [[{
                            reasoning: failureReason,
                            action_type: 'Test Failed'
                        }]]
                    }
                }"
                :index="steps.length"
                :expanded="expandedState"
                :is-last-step="true"
                :test-status="testStatus"
                :artifacts="{}"
                ref="stepRefs"
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import StepItem from './StepItem.vue'

const props = defineProps({
    steps: {
        type: Array,
        default: () => []
    },
    artifacts: {
        type: Object,
        default: () => ({})
    },
    testStatus: {
        type: String,
        default: 'pending'
    },
    failureReason: {
        type: String,
        default: ''
    }
})

const expandedState = ref(false)
const stepRefs = ref([])

const expandAll = () => {
  stepRefs.value.forEach(stepRef => {
    if (stepRef) {
      stepRef.setExpanded(true)
    }
  })
}

const collapseAll = () => {
  stepRefs.value.forEach(stepRef => {
    if (stepRef) {
      stepRef.setExpanded(false)
    }
  })
}
</script>