<script setup>
import { ARTIFACT_URL } from '../config'
import { onMounted, computed, ref, watch } from 'vue'

const props = defineProps({
    recordingUrl: {
        type: String,
        required: false,
        default: null
    }
})

const videoRef = ref(null)

const recordingPath = computed(() => {
    if (!props.recordingUrl) return null
    return props.recordingUrl.startsWith("file:///") 
        ? props.recordingUrl.slice(7) 
        : props.recordingUrl
})

const setVideoTime = () => {
    if (!videoRef.value) return
    
    const handleMetadata = () => {
        videoRef.value.currentTime = 2
        videoRef.value.removeEventListener('loadedmetadata', handleMetadata)
    }
    
    if (videoRef.value.readyState >= 1) {
        videoRef.value.currentTime = 2
    } else {
        videoRef.value.addEventListener('loadedmetadata', handleMetadata)
    }
}

watch(() => props.recordingUrl, () => {
    if (videoRef.value) {
        videoRef.value.load()
        setVideoTime()
    }
}, { immediate: true })

onMounted(() => {
    if (videoRef.value) {
        setVideoTime()
    }
})
</script>

<template>
    <div v-if="recordingPath" class="px-6 py-4 border-b border-gray-200">
        <h2 class="text-xl font-semibold mb-4">Recording</h2>
        <video ref="videoRef" controls class="w-full">
            <source :src="`/api/v1/artifact/recording?path=${encodeURIComponent(recordingPath)}`" type="video/webm">
            Your browser does not support the video tag.
        </video>
    </div>
</template>