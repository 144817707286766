<template>
  <template v-for="action in allActions" 
            :key="action.uniqueId">
    <div class="action-item-container">
      <div class="action-item bg-white dark:bg-gray-800" 
           :class="getActionBorderClass(action)"
           :data-step-id="action.stepId">
        <div class="action-header cursor-pointer" @click="toggleAction(action.uniqueId)">
          <div class="flex items-center gap-4">
            <!-- We'll update this number via onMounted -->
            <div class="action-number bg-blue-500 dark:bg-blue-600">1</div>
            <div>
              <p class="font-medium text-gray-600 dark:text-gray-400 reasoning-text">
                {{ action.reasoning || 'No reasoning available' }}
              </p>
            </div>
          </div>
          <div class="action-toggle">
            <span class="action-toggle-text text-gray-600 dark:text-gray-400">
              {{ isActionExpanded(action.uniqueId) ? 'Hide Details' : 'Show Details' }}
            </span>
            <svg class="action-toggle-icon w-5 h-5 text-gray-500 dark:text-gray-400" 
                 :class="{ expanded: isActionExpanded(action.uniqueId) }" 
                 fill="none" 
                 stroke="currentColor" 
                 viewBox="0 0 24 24">
              <path stroke-linecap="round" 
                    stroke-linejoin="round" 
                    stroke-width="2" 
                    d="M19 9l-7 7-7-7" />
            </svg>
          </div>
        </div>
        <div class="action-content px-4" :class="{ expanded: isActionExpanded(action.uniqueId) }">
          <div class="content-container bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
            <div class="action-type text-gray-900 dark:text-white">{{ action.type }}</div>
            <div v-if="action.element" class="element-info">
              <span class="font-medium">Element:</span> {{ action.element.tagName }}
              <span v-if="action.element.attributes?.class" class="text-gray-500">({{ action.element.attributes.class }})</span>
            </div>
            <div v-if="action.screenshot" class="image-container">
              <img :src="`/api/v1/artifact/image?path=${encodeURIComponent(action.screenshot)}`"
                   :alt="`Action Screenshot`" 
                   class="w-full rounded-lg shadow-sm"
                   loading="lazy">
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue'
import { BASE_URL, sanitizeFilePath } from '../config'

const props = defineProps({
  step: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
})

const expandedActions = ref(new Set())

// Fix the setExpanded function
const setExpanded = (value) => {
  if (value) {
    // When expanding, add all action IDs to the set
    allActions.value.forEach(action => {
      expandedActions.value.add(action.uniqueId)
    })
  } else {
    // When collapsing, clear all
    expandedActions.value.clear()
  }
}

// Make sure to expose the setExpanded function
defineExpose({
  setExpanded
})

// Number the actions after mount
onMounted(async () => {
  await nextTick()
  const numberElements = document.querySelectorAll('.action-number')
  numberElements.forEach((el, index) => {
    el.textContent = index + 1
  })
})

let actionNumber = 1

const stepArtifacts = ref([])

onMounted(async () => {
  if (!props.step?.task_id || !props.step?.step_id) return
  
  try {
    const response = await fetch(
      `${BASE_URL}/tasks/${props.step.task_id}/steps/${props.step.step_id}/artifacts`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    if (!response.ok) {
      throw new Error('Failed to fetch artifacts')
    }
    stepArtifacts.value = await response.json()
  } catch (error) {
    console.error("Error loading artifacts:", error)
  }
})

const allActions = computed(() => {
  const actionsAndResults = props.step?.output?.actions_and_results || []
  
  // Get all screenshot_action artifacts and sort by creation time
  const screenshots = stepArtifacts.value
    .filter(artifact => artifact.artifact_type === "screenshot_action")
    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))

  return actionsAndResults.map((actionAndResult, index) => {
    // Get the screenshot that corresponds to this action's index
    const actionScreenshot = screenshots[index]
    const screenshotPath = actionScreenshot ? sanitizeFilePath(actionScreenshot.uri) : null

    return {
      uniqueId: `${props.step.step_id}-${index}`,
      stepId: props.step.step_id,
      type: actionAndResult[0].action_type,
      reasoning: actionAndResult[0].reasoning || '',
      success: actionAndResult[1]?.[0]?.success || false,
      element: actionAndResult[0].skyvern_element_data || null,
      screenshot: screenshotPath,
      index,
      isLastAction: props.step.is_last && index === actionsAndResults.length - 1,
      testStatus: props.step.status
    }
  })
})

const toggleAction = (actionId) => {
  if (expandedActions.value.has(actionId)) {
    expandedActions.value.delete(actionId)
  } else {
    expandedActions.value.add(actionId)
  }
}

const isActionExpanded = (actionId) => {
  return expandedActions.value.has(actionId)
}

const getActionBorderClass = (action) => {
  if (action.isLastAction && action.testStatus === 'failed') {
    return 'border-l-red-500 dark:border-l-red-400 !important'
  }
  return 'border-l-green-500 dark:border-l-green-400'
}

onMounted(async () => {
  // Wait for the next DOM update
  await nextTick()
  
  // Get all action-item-containers and number them
  const containers = document.querySelectorAll('.action-item-container')
  containers.forEach((container, index) => {
    // Find the number element inside this container
    const numberEl = container.querySelector('.action-number')
    if (numberEl) {
      numberEl.textContent = index + 1
    }
  })
  
  const numberElements = document.querySelectorAll('.action-number')
  numberElements.forEach((el, index) => {
    el.textContent = index + 1
  })
})
</script>

<style scoped>
.action-item {
    @apply relative p-4 border-l-4 transition-all;
    border-left-color: #22c55e !important; /* Force green in light mode */
}

/* Force green color for connecting lines */
.action-item-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 1rem;
    width: 3px;
    height: 100%;
    background-color: #22c55e !important; /* Force green in both modes */
    z-index: -1;
}

.action-number {
    @apply absolute -left-6 top-1/2 -translate-y-1/2 w-8 h-8 rounded-full bg-blue-500 dark:bg-blue-600 text-white flex items-center justify-center font-semibold;
}

/* Force green for connecting lines except last action on failure */
.action-item-container:first-child::before {
    top: 50%;
    background-color: #22c55e !important;
}

.action-item-container:last-child::before {
    height: 50%;
    background-color: #22c55e !important;
}

/* Style for last action in failed test */
.action-item.border-l-red-500 {
    border-left-color: #ef4444 !important;
}

.action-content {
    @apply mt-4 overflow-hidden max-h-0 transition-all duration-300;
}

.action-content.expanded {
    @apply max-h-[2000px];
}

.content-container {
    @apply p-4 rounded-lg;
}

.action-toggle-icon {
    @apply transition-transform duration-200;
}

.action-toggle-icon.expanded {
    @apply rotate-180;
}

.action-header {
    @apply flex justify-between items-center;
}
</style>