<!-- src/views/LiveTasksView.vue -->
<template>
  <div class="container mx-auto px-4 py-8 h-full" style="height: 100%;">
    <!-- Header -->
    <div class="mb-6">
      <h1 class="text-2xl font-semibold text-gray-900 dark:text-white">Live Tasks</h1>
      <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">Currently running automation tasks</p>
    </div>

    <!-- Tasks Grid -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <div v-for="task in liveTasks" :key="task.task_id" @click="navigateToTask(task)"
        class="cursor-pointer p-4 rounded-lg border border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors">
        <!-- Task Card Content -->
        <div class="flex flex-col space-y-2">
          <div class="flex items-center justify-between">
            <div class="flex items-center space-x-2">
              <template v-if="task.request?.url">
                <img :src="getFaviconUrl(task.request.url)" :alt="getHostname(task.request.url)"
                  class="w-4 h-4 object-contain" @error="(e) => e.target.src = '/favicon-fallback.png'" />
              </template>
              <span v-else class="material-icons text-gray-400 dark:text-gray-500">
                language
              </span>
              <span class="text-blue-600 dark:text-blue-400">
                {{ task.title || task.request?.title || 'Untitled Task' }}
              </span>
            </div>
            <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium" :class="{
              'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200': task.status === 'running',
              'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200': task.status === 'completed',
              'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200': task.status === 'failed',
              'bg-yellow-100 text-yellow-800 dark:text-yellow-200': task.status === 'terminated',
              'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200': task.status === 'canceled'
            }">
              {{ task.status.charAt(0).toUpperCase() + task.status.slice(1) }}
            </span>
          </div>
          <p class="text-sm text-gray-600 dark:text-gray-400 truncate">
            {{ task.request?.url }}
          </p>
          <div class="flex items-center justify-between">
            <p class="text-xs text-gray-500 dark:text-gray-500">
              Started: {{ toLocalTime(task.created_at) }}
            </p>
            <div class="flex items-center justify-end w-full">
              <div v-if="task.status === 'running'" class="w-5 h-5 mr-2">
                <svg class="animate-spin" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#0579fe" stroke-width="4" fill="none" />
                  <path class="opacity-75" fill="#0579fe"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
              </div>
              <button v-if="task.status === 'running'" @click.stop="handleCancelTask(task.task_id)"
                :disabled="cancellingTasks.has(task.task_id)"
                class="inline-flex items-center px-2 py-1 text-xs font-medium rounded-md text-red-700 bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-500 disabled:opacity-50">
                <svg v-if="cancellingTasks.has(task.task_id)" class="animate-spin -ml-1 mr-1 h-3 w-3"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
                {{ cancellingTasks.has(task.task_id) ? 'Cancelling...' : 'Cancel' }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- No Tasks Message -->
      <div v-if="liveTasks.length === 0" class="col-span-full text-center py-8 text-gray-500 dark:text-gray-400">
        No tasks currently running
      </div>
    </div>

    <!-- Add after the tasks grid -->
    <div class="relative my-8">
      <div class="full-width-line-container" aria-hidden="true">
        <div class="full-width-line"></div>
      </div>
      <div class="relative flex justify-center">
        <span class="px-3 bg-white dark:bg-gray-900 text-sm text-gray-500 dark:text-gray-400">
          System Statistics
        </span>
      </div>
    </div>

    <!-- Then your existing stats grid -->
    <div class="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <!-- Task Statistics Card -->
      <div class="p-6 rounded-lg border border-gray-200 dark:border-gray-700">
        <h2 class="text-lg font-semibold mb-4 text-gray-900 dark:text-white">Task Statistics</h2>
        <div class="space-y-3">
          <div class="flex justify-between">
            <span class="text-gray-600 dark:text-gray-400">Active Tasks</span>
            <span class="font-medium">{{ liveTasks.length }}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-gray-600 dark:text-gray-400">Average Duration</span>
            <span class="font-medium">{{ calculateAverageDuration() }}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-gray-600 dark:text-gray-400">Success Rate (24h)</span>
            <span class="font-medium">{{ calculateSuccessRate() }}%</span>
          </div>
        </div>
      </div>

      <!-- Resource Monitor -->
      <div class="p-6 rounded-lg border border-gray-200 dark:border-gray-700">
        <h2 class="text-lg font-semibold mb-4 text-gray-900 dark:text-white">Resource Monitor</h2>
        <div class="space-y-3">
          <div>
            <div class="flex justify-between mb-1">
              <span class="text-gray-600 dark:text-gray-400">CPU Load</span>
              <span class="text-sm">{{ cpuInfo.load }}% ({{ cpuInfo.cores }} cores)</span>
            </div>
            <div class="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700">
              <div class="bg-blue-600 h-2 rounded-full transition-all duration-300"
                :style="{ width: `${cpuInfo.load}%` }">
              </div>
            </div>
          </div>
          <div>
            <div class="flex justify-between mb-1">
              <span class="text-gray-600 dark:text-gray-400">Memory Usage</span>
              <span class="text-sm">{{ memoryInfo.usage }}%</span>
            </div>
            <div class="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700">
              <div class="bg-green-600 h-2 rounded-full transition-all duration-300"
                :style="{ width: `${memoryInfo.usage}%` }"></div>
            </div>
            <div class="text-xs text-gray-500 mt-1">
              {{ formatMemory(memoryInfo.free) }} free of {{ formatMemory(memoryInfo.total) }}
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4 mt-4">
            <div class="text-center">
              <div class="text-2xl font-bold text-blue-600 dark:text-blue-400">
                {{ queuedTasks }}
              </div>
              <div class="text-sm text-gray-600 dark:text-gray-400">Queued Tasks</div>
            </div>
            <div class="text-center">
              <div class="text-2xl font-bold text-green-600 dark:text-green-400">
                {{ completedToday }}
              </div>
              <div class="text-sm text-gray-600 dark:text-gray-400">Completed Today</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Recent Activity -->
      <div class="p-6 rounded-lg border border-gray-200 dark:border-gray-700">
        <h2 class="text-lg font-semibold mb-4 text-gray-900 dark:text-white">Recent Activity</h2>
        <div class="space-y-4">
          <div v-for="activity in recentActivities" :key="activity.id" class="flex items-center gap-3">
            <div :class="`w-2 h-2 rounded-full ${getStatusColor(activity.status)}`"></div>
            <div class="flex-1 min-w-0">
              <p class="text-sm text-gray-900 dark:text-white truncate">{{ activity.url }}</p>
              <p class="text-xs text-gray-500">{{ formatTime(activity.timestamp) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { BASE_URL } from '../config'
import { swal } from '../utils/swal'
import { toLocalTime } from '../utils/dateConversion'

const router = useRouter()
const liveTasks = ref([])
const cancellingTasks = ref(new Set())

const systemLoad = ref(0)
const queuedTasks = ref(0)
const completedToday = ref(0)
const recentActivities = ref([])

let pollInterval

const cpuInfo = ref({
  load: 0,
  cores: 0,
  model: ''
})
const memoryInfo = ref({
  total: 0,
  free: 0,
  usage: 0
})
const systemInfo = ref({
  uptime: 0,
  platform: '',
  hostname: ''
})


// Safe URL parsing function
const getFaviconUrl = (url) => {
  try {
    const hostname = new URL(url).hostname

    return `https://www.google.com/s2/favicons?domain=${hostname}&sz=32`
  } catch {
    return null
  }
}

const getHostname = (url) => {
  try {
    return new URL(url).hostname
  } catch {
    return 'unknown'
  }
}

const calculateAverageDuration = () => {
  if (liveTasks.value.length === 0) return '0m'

  const now = new Date()

  const durations = liveTasks.value.map(task => {
    const start = new Date(task.created_at)

    return (now - start) / 1000 / 60 // minutes
  })

  const avg = durations.reduce((a, b) => a + b, 0) / durations.length

  return `${Math.round(avg)}m`
}

// Calculate success rate for last 24 hours - Currently estimated. Fix later.
const calculateSuccessRate = () => 85
const formatTime = (timestamp) => dateConversion.toLocalTime(timestamp)
const formatMemory = (bytes) => (bytes / (1024 * 1024 * 1024)).toFixed(1) + ' GB'
const navigateToTask = (task) => task.task_id ? router.push(`/tasks/${task.task_id}`) : null

const getStatusColor = (status) => {
  const colors = {
    running: 'bg-blue-500',
    completed: 'bg-green-500',
    failed: 'bg-red-500',
    terminated: 'bg-yellow-500'
  }
  return colors[status] || 'bg-gray-500'
}

// Enhanced fetchLiveTasks to include additional metrics
const fetchLiveTasks = async () => {
  try {
    const [tasksResponse, statsResponse] = await Promise.all([
      fetch(`${BASE_URL}/tasks?task_status=running`, {
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' }
      }),
      fetch(`${BASE_URL}/statistics`, {
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' }
      })
    ])

    if (!tasksResponse.ok || !statsResponse.ok) throw new Error('Failed to fetch data')

    const tasks = await tasksResponse.json()
    const stats = await statsResponse.json()

    liveTasks.value = tasks.filter(task => task.status === 'running')

    systemLoad.value = stats.cpu.load
    cpuInfo.value = stats.cpu
    memoryInfo.value = stats.memory

    systemInfo.value = {
      uptime: stats.uptime,
      platform: stats.platform,
      hostname: stats.hostname
    }

    queuedTasks.value = stats.queued || 0
    completedToday.value = stats.completedToday || 0
    recentActivities.value = stats.recentActivities || []
  } catch (error) {
    console.error('Error fetching tasks:', error)
  }
}

onMounted(() => {
  fetchLiveTasks()

  pollInterval = setInterval(fetchLiveTasks, 5000)
})

// Clean up on component unmount
onUnmounted(() => {
  if (pollInterval) clearInterval(pollInterval)
})

// Add this function in script setup
const handleCancelTask = async (taskId) => {
  try {
    const result = await swal.confirm(
      'Cancel Task?',
      'Are you sure you want to cancel this task? This action cannot be undone.'
    )

    if (!result.isConfirmed) return

    await swal.success('Cancelled!', 'The task has been cancelled.')

    const response = await fetch(`${BASE_URL}/tasksNew/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ taskId })
    })

    if (!response.ok) throw new Error('Failed to cancel task')

    const taskIndex = liveTasks.value.findIndex(t => t.id === taskId)

    if (taskIndex !== -1) liveTasks.value[taskIndex].status = 'canceled'

  } catch (error) {
    console.error('Failed to cancel task:', error)
  } finally {
    cancellingTasks.value.delete(taskId)
  }
}

</script>

<style scoped>
.full-width-line-container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
}

.full-width-line {
  width: 100%;
  border-top: 1px solid;
  border-color: var(--border-color, #e0e0e0);
  /* Default color */
}

.dark .full-width-line {
  border-color: var(--border-color-dark, #4a4a4a);
  /* Dark mode color */
}

.flex.items-center.justify-end.w-full {
  justify-content: flex-end;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

/* Add smooth transitions for hover effects */
.group {
  transition: all 0.2s ease-in-out;
}

/* Add loading animation for task preview images */
.task-preview img {
  transition: opacity 0.2s ease-in-out;
}

.task-preview img.loading {
  opacity: 0;
}

.task-preview img.loaded {
  opacity: 1;
}

/* Status badge styles */
.status-badge {
  @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium;
}

.status-badge.running {
  @apply bg-blue-100 text-blue-800;
}

.status-badge.completed {
  @apply bg-green-100 text-green-800;
}

.status-badge.failed {
  @apply bg-red-100 text-red-800;
}

/* Selected task highlight */
.selected-task {
  @apply ring-2 ring-blue-500;
}

/* Actions list scrollbar styling */
.actions-list {
  scrollbar-width: thin;
  scrollbar-color: theme('colors.gray.300') theme('colors.gray.100');
}

.actions-list::-webkit-scrollbar {
  width: 6px;
}

.actions-list::-webkit-scrollbar-track {
  @apply bg-gray-100 rounded;
}

.actions-list::-webkit-scrollbar-thumb {
  @apply bg-gray-300 rounded;
  border: 2px solid transparent;
}

/* Add animation for refresh button */
.refresh-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Zoom animation for task selection */
.task-zoom-in {
  animation: zoomIn 0.2s ease-out;
}

@keyframes zoomIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}
</style>