<template>
  <Navbar v-if="$route.path !== '/login'" />
  <main :class="{ 'pt-16': $route.path !== '/login' }" class="min-h-screen transition-colors">
    <router-view></router-view>
  </main>
</template>

<script setup>
import Navbar from './components/Navbar.vue'
import { onMounted, watch } from 'vue'
import { useThemeStore } from './store/theme'

const themeStore = useThemeStore()

// Update HTML class for theme
const updateHTMLClass = (isDark) => {
  document.documentElement.classList.toggle('dark', isDark)
}

// Initial setup
onMounted(() => {
  updateHTMLClass(themeStore.isDark)
})

// Watch for theme changes
watch(
  () => themeStore.isDark,
  (newValue) => updateHTMLClass(newValue)
)
</script>

<style>
html {
  @apply bg-gray-50 transition-colors duration-200;
}

html.dark {
  @apply bg-gray-900;
}

#app {
  min-height: 100vh;
}
</style>