<template>
  <div class="min-h-screen bg-gray-50 dark:bg-gray-900 py-8">
    <div class="max-w-3xl mx-auto">
      <!-- Header with Icon -->
      <header class="mb-8 px-4">
        <div class="flex items-center gap-3 mb-2">
          <div class="p-2 bg-blue-100 dark:bg-blue-900/50 rounded-lg">
            <PlusIcon class="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h1 class="text-3xl font-semibold text-gray-900 dark:text-white">Create New Test</h1>
        </div>
        <p class="text-gray-600 dark:text-gray-400 ml-12">Configure your automated test workflow</p>
      </header>

      <!-- Form -->
      <form @submit.prevent="handleSubmit" class="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
        <!-- URL Section -->
        <div class="p-6 border-b border-gray-200 dark:border-gray-700">
          <div class="flex items-start gap-4">
            <div class="p-2 mt-1 bg-purple-100 dark:bg-purple-900/50 rounded-lg">
              <GlobeIcon class="w-5 h-5 text-purple-600 dark:text-purple-400" />
            </div>
            <div class="flex-1 space-y-2">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Target URL <span class="text-red-500">*</span>
              </label>
              <input 
                v-model="formData.url" 
                type="url" 
                required 
                placeholder="https://"
                class="w-full px-4 py-2 text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all" 
              />
            </div>
          </div>
        </div>

        <!-- Test Details Section -->
        <div class="p-6 border-b border-gray-200 dark:border-gray-700">
          <div class="flex items-start gap-4">
            <div class="p-2 mt-1 bg-green-100 dark:bg-green-900/50 rounded-lg">
              <ClipboardIcon class="w-5 h-5 text-green-600 dark:text-green-400" />
            </div>
            <div class="flex-1 space-y-6">
              <!-- Title -->
              <div class="space-y-2">
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Test Title <span class="text-red-500">*</span>
                </label>
                <input 
                  v-model="formData.title" 
                  type="text" 
                  required 
                  placeholder="Name your test"
                  class="w-full px-4 py-2 text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all" 
                />
              </div>
              
              <!-- Description -->
              <div class="space-y-2">
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Description
                </label>
                <textarea 
                  v-model="formData.description" 
                  rows="3" 
                  placeholder="Describe what this test should accomplish"
                  class="w-full px-4 py-2 text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all" 
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Navigation Goal Section -->
        <div class="p-6">
          <div class="flex items-start gap-4">
            <div class="p-2 mt-1 bg-orange-100 dark:bg-orange-900/50 rounded-lg">
              <NavigationIcon class="w-5 h-5 text-orange-600 dark:text-orange-400" />
            </div>
            <div class="flex-1 space-y-2">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Navigation Goal <span class="text-red-500">*</span>
              </label>
              <textarea 
                v-model="formData.navigation_goal" 
                required 
                rows="4" 
                placeholder="Describe the steps and actions the test should take"
                class="w-full px-4 py-2 text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all" 
              />
            </div>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="px-6 py-4 bg-gray-50 dark:bg-gray-800/50 border-t border-gray-200 dark:border-gray-700 flex justify-end">
          <button 
            type="submit"
            :disabled="isLoading"
            class="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors flex items-center gap-2"
          >
            <SpinnerIcon v-if="isLoading" class="animate-spin w-4 h-4" />
            <span>Create Test</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { BASE_URL } from '../config'
import { PlusIcon, GlobeIcon, ClipboardIcon, NavigationIcon, SpinnerIcon } from '@heroicons/vue/24/outline'

const router = useRouter()

const formData = ref({
  url: '',
  title: '',
  description: '',
  navigation_goal: '',
  data_extraction_goal: '',
  navigation_payload: '',
  workflow_definition: {
    description: '',
    blocks: []
  },
  proxy_location: null,
  webhook_callback_url: null
})

const handleSubmit = async () => {
  try {
    const blockName = formData.value.title || "New Template"
    const requestData = {
      title: blockName,
      description: formData.value.description || "",
      is_saved_task: true,
      proxy_location: "RESIDENTIAL",
      webhook_callback_url: formData.value.webhook_callback_url || "",
      workflow_definition: {
        parameters: [
          {
            parameter_type: "workflow",
            key: "navigation_payload",
            workflow_parameter_type: "json",
            default_value: {
              url: formData.value.url,
              method: "GET",
              headers: {}
            }
          }
        ],
        blocks: [
          {
            label: blockName,
            block_type: "task",
            task_type: "general",
            url: formData.value.url,
            title: blockName,
            navigation_goal: formData.value.navigation_goal || null,
            data_extraction_goal: formData.value.data_extraction_goal || null,
            continue_on_failure: false,
            complete_criterion: null,
            terminate_criterion: null,
            parameters: [
              {
                key: "navigation_payload",
                value: {
                  url: formData.value.url,
                  method: "GET",
                  headers: {}
                }
              }
            ]
          }
        ]
      }
    }

    const response = await fetch(`${BASE_URL}/tasks/save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    })

    if (!response.ok) {
      throw new Error('Failed to create test')
    }
    const data = await response.json()
    router.push(`/availableTests`)
  } catch (error) {
    console.error('Error creating test:', error)
  }
}
</script>